/* Header bg */
.header-bg {
  background-image: url("/src/assets/png/headerBg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.header-sec {
  overflow-x: clip;
}
/* ---- */
/* NAVBAR */
.nav-bg {
  background: rgba(255, 255, 255, 0.24);
  backdrop-filter: blur(8px);
}
.nav ul {
  gap: 34px;
}
.nav ul li a {
  transition: 0.2s ease-in-out;
}
.nav ul li a:hover {
  opacity: 1;
}
.home:after {
  content: "";
  position: absolute;
  bottom: -5px;
  left: -10%;
  width: 120%;
  background: var(--blue-linear);
  height: 2px;
  border-radius: 5px;
}
.product:after,
.gallery:after,
.about:after,
.service:after {
  content: "";
  position: absolute;
  bottom: -5px;
  left: -10%;
  width: 120%;
  background: var(--clr-white);
  height: 2px;
  border-radius: 5px;
}

.product-page-button {
  border-radius: 12px;
  border: 1px solid var(--White, #fff) !important;
  background: rgba(255, 255, 255, 0.1) !important;
  transition: 0.3s ease-in-out;
}
.product-page-button:hover {
  background: var(--blue-linear) !important;
}
/* ----- */
/* HERO SECTION */
.heading-text-stroke {
  -webkit-text-stroke: 1.5px;
}
.para-width {
  max-width: 520px;
}
.countings-vertical-line {
  height: 100px;
  width: 1px;
  border-radius: 5px;
  background: var(--blue-linear-2);
}
.plus {
  background: var(--blue-linear);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
/* ----- */
/* ABOUT US */
.about-us-para {
  max-width: 100%;
}
.about-us-sec-img {
  width: 100%;
  max-width: 429px;
}
/* ----- */
/* OUR SERVICES */
.sec-our-service {
  margin-top: -4px;
}
.top-wave {
  height: 18px;
}
.bottom-wave {
  margin-top: -20px;
  height: 18px;
}
.cabin-design-gif {
  max-width: 544px;
  width: 100%;
  border-radius: 12px;
}
.gif-text {
  top: 18%;
  right: 2px;
  padding: 13px;
  max-width: 177px;
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.4);
  background: rgba(0, 0, 0, 0.12);
  backdrop-filter: blur(6px);
}
/* ------- */
/* ELEVATOR EXPERTIZE */
.expertise-cards .bg-blue-circle {
  background-color: #f0f7fa;
  width: 120px;
  height: 120px;
  stroke-width: 1px;
  stroke: rgba(0, 0, 0, 0.04);
  border-radius: 100%;
}
.expertise-cards {
  max-width: 380px;
  transition: 0.4s ease-in-out;
}
.expertise-cards-2 {
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.1);
}
.expertise-cards:hover {
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 2px 34px 0px rgba(0, 0, 0, 0.1);
}
.expertise-cards::after,
.our-offering-cards::after {
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 0%;
  content: "";
  color: transparent;
  background: var(--blue-linear);
  height: 5px;
  transition: 0.3s ease-in-out;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}
.expertise-cards:hover:after,
.our-offering-cards:hover::after {
  width: 100%;
}
.elevator-expertise-sec .prev-next-btn {
  border: 2px solid transparent;
  border-radius: 8px;
  background: linear-gradient(white, white) padding-box,
    var(--blue-linear) border-box;
  width: 40px;
  height: 40px;
}
.elevator-expertise-sec .prev-next-btn:hover {
  background: var(--blue-linear);
  border: 2px;
}
.elevator-expertise-sec .prev-next-btn:active {
  scale: 0.9;
}
.elevator-expertise-sec .prev-next-btn:hover svg path {
  fill: #fff;
}
/* ---- */
/* WHY CHOOSE US */
.why-choose-us-sec .bg-blue-circle {
  background-color: #dde7ed;
  width: 100px;
  height: 100px;
  stroke-width: 1px;
  stroke: rgba(0, 0, 0, 0.04);
  border-radius: 100%;
}
.why-choose-us-sec {
  margin-top: -4px;
}
/* ---- */
/* OUR OFFERING */
.our-offering-cards {
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  background: #fff;
  padding: 12px;
  position: relative;
}
.our-offering-cards:hover {
  box-shadow: 0px 2px 34px 0px rgba(0, 0, 0, 0.1);
}
.our-offering-cards:hover svg {
  margin-left: 5px;
}
.image-div-border-radius {
  border-radius: 8px;
}
.our-offering-cards:hover img {
  scale: 1.1;
}
.text-read-more {
  background: var(--Blue, linear-gradient(180deg, #1194d9 0%, #0570a8 100%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
/* ---- */
/* GET IN TOUCH */
.sec-get-in-touch {
  margin-top: -5px;
}
.sec-get-in-touch .bg-blue-circle {
  background-color: #e5ecf0;
  width: 50px;
  height: 50px;
  stroke-width: 1px;
  stroke: rgba(0, 0, 0, 0.04);
  border-radius: 100%;
}
.call-number,
.mail-text {
  -webkit-text-stroke: 0.3px;
}
.get-in-touch-vr-line {
  width: 1px;
  height: 40px;
  background: var(--blue-linear);
}
.receptionist {
  border-radius: 12px;
  width: 100%;
  max-width: 450px;
}
/* --- */
/* EXPLORE OUR COLLECTION */
.collection-img-div {
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.08);
}
.collection-img-div:hover img {
  scale: 1.1;
}
.sec-our-collection .prev-next-btn,
.sec-our-clients .prev-next-btn {
  border: 2px solid transparent;
  border-radius: 8px;
  background: linear-gradient(white, white) padding-box,
    var(--blue-linear) border-box;
  width: 40px;
  height: 40px;
}
.sec-our-collection .prev-next-btn:hover,
.sec-our-clients .prev-next-btn:hover {
  background: var(--blue-linear);
  border: 2px;
}
.sec-our-collection .prev-next-btn:active,
.sec-our-clients .prev-next-btn:active {
  scale: 0.9;
}
.sec-our-collection .prev-next-btn:hover svg path,
.sec-our-clients .prev-next-btn:hover svg path {
  fill: #fff;
}
/* --- */
/* Words From Our Clients */
.sec-our-clients {
  margin-top: -5px;
}
.our-clients-card {
  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.06);
}
.our-clients-card:hover {
  background: #f7f7f7;
  box-shadow: 0px 2px 34px 0px rgba(0, 0, 0, 0.06);
}
.our-clients-card .white-circle {
  width: 54px;
  height: 54px;
  background-color: var(--clr-white);
  border-radius: 100%;
}
/* ---- */
/* FOOTER */
.social-sites-logo-bg {
  width: 40px;
  height: 40px;
  background: var(--blue-linear);
  border-radius: 100%;
}
.footer ul li a {
  transition: 0.2s ease-in-out;
}
.footer ul li a:hover,
.address:hover {
  opacity: var(--opacity-09);
}
.hr_line {
  height: 1px;
  max-width: 100%;
  margin-top: 40px;
  background-color: #fff;
  opacity: 0.3;
}
/* ------ */
/* COMMERCIAL ELEVATORS */
.products-header-bg {
  background-image: url("../src/assets/png/headerbluebgsm.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.products-header-bg-vh {
  height: 20vh;
}
.product-heading-text-stroke {
  -webkit-text-stroke: 0.9px;
}
/* ------- */
/* ABOUT US PAGE */
.gradient-circle {
  width: 24px;
  height: 24px;
  border-radius: 100%;
  background: var(--blue-linear);
}
.our-mission {
  border-radius: 12px;
  background: var(--blue-linear);
}
.our-vision {
  border-radius: 12px;
  background: var(--clr-grey-2);
}
.map-border {
  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.northindiamap {
  max-width: 460px;
  width: 100%;
}
.northindiamap img {
  max-width: 312px;
  width: 100%;
}
.team {
  max-width: 440px;
  width: 100%;
}
.deliverytruck {
  max-width: 450px;
  width: 100%;
}
/* ---- */
/* GALLERY */

.gallery-sec .prev-next-btn {
  border: 2px solid transparent;
  border-radius: 8px;
  background: linear-gradient(white, white) padding-box,
    var(--blue-linear) border-box;
  width: 40px;
  height: 40px;
}
.gallery-sec .prev-next-btn:hover {
  background: var(--blue-linear);
  border: 2px;
}
.gallery-sec .prev-next-btn:active {
  scale: 0.9;
}
.gallery-sec .prev-next-btn:hover svg path {
  fill: #fff;
}

/* ----- */
@media (min-width: 575.98px) {
  .about-us-para {
    max-width: 560px;
  }
  .top-wave {
    height: 35px;
  }
  .bottom-wave {
    height: 35px;
  }
  .products-header-bg {
    background-size: 100% 100%;
  }
  .products-header-bg-vh {
    height: 26vh;
  }
}
@media (min-width: 767.98px) {
  .products-header-bg {
    background-image: url("../src/assets/png/headerbluebg.png");
    background-size: 100% 90%;
  }
}
@media (min-width: 991.98px) {
  .about-us-para {
    max-width: 560px;
  }
  .top-wave {
    height: 35px;
  }
  .sec-our-service {
    margin-top: -15px;
  }
  .why-choose-us-sec {
    margin-top: -11px;
  }
  .products-header-bg {
    background-size: 100% 80%;
  }
  .products-header-bg-vh {
    height: 40vh;
  }
}
@media (min-width: 1199.98px) {
  .products-header-bg {
    background-image: url("../src/assets/png/headerbluebg.png");
    background-size: contain;
  }
}
@media (min-width: 1399.98px) {
  .about-us-para {
    max-width: 560px;
  }
  .gif-text {
    right: 10px;
    max-width: 197px;
  }
  .products-header-bg-vh {
    height: 30vh;
  }
}
@media (max-width: 992px) {
  .nav-hidden {
    top: 0;
    right: -100%;
    position: fixed;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
    justify-content: center;
    background: #fff;
    z-index: 10;
    transition: 0.3s ease-in-out;
  }
  .nav-show {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 10;
    background: #fff;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    min-height: 100vh;
    transition: 0.4s ease-in-out;
  }
  .bg-linear {
    background: var(--blue-linear);
  }
  .hamburger {
    background-color: transparent;
    border: none;
    position: relative;
    z-index: 10;
  }
  .bar {
    display: block;
    width: 25px;
    height: 3px;
    margin-top: 5px;
    margin-bottom: 5px;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.4s ease-in-out;
    background-color: black;
    z-index: 10;
    border-radius: 5px;
  }
  .white-bar {
    background-color: var(--clr-white);
  }
  .hamburger-2 {
    background-color: transparent;
    border: none;
    position: relative;
    z-index: 10;
  }
  .hamburger-2 .bar:nth-child(2) {
    opacity: 0;
    z-index: 10;
  }
  .hamburger-2 .bar:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
    z-index: 10;
  }
  .hamburger-2 .bar:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
    z-index: 10;
  }
  .choose-us-data {
    align-items: center;
  }
}
