@import url("https://fonts.googleapis.com/css2?family=DM+Sans:opsz@9..40&family=Merriweather&family=Open+Sans&family=Petrona:wght@500&family=Poppins:wght@300&family=Prata&family=Raleway:wght@600&display=swap");
@import url("./Root.css");
* {
  list-style-type: none !important;
  text-decoration: none !important;
}
.logo {
  max-width: 140px;
  position: relative;
  z-index: 5;
}
.cursor_pointer {
  cursor: pointer;
}
/* PRELOADER */
.hr-line {
  width: 270px;
  height: 1px;
  background-color: #000;
}
.preloader-logo {
  max-width: 80px;
  width: 100%;
}
/* ----- */
/* FONT FAMILY */
.ff-arial {
  font-family: Arial, Helvetica, sans-serif;
}
.ff-poppins {
  font-family: "Poppins", sans-serif;
}
.ff-raleway {
  font-family: "Raleway", sans-serif;
}
.ff-petrona {
  font-family: "Petrona", serif;
}
.ff-merri {
  font-family: "Merriweather", serif;
}
.ff-open {
  font-family: "Open Sans", sans-serif;
}
.ff-dmsans {
  font-family: "DM Sans", sans-serif;
}
.ff-prata {
  font-family: "Prata", serif;
}
.fw_normal {
  font-weight: 400;
}
@font-face {
  font-family: "Baskerville old face";
  src: url("/src/assets/fonts/BASKVILL.ttf");
  font-style: normal;
}
.ff-baskerville {
  font-family: "Baskerville old face";
}
/* ------------- */
.text-stroke {
  -webkit-text-stroke: 0.9px #000;
  -webkit-fill-color: transparent;
}
/* FONT SIZE */
.fs_xxsm {
  font-size: var(--fs-xxsm);
}
.fs_xsm {
  font-size: var(--fs-xsm);
}
.fs_sm {
  font-size: var(--fs-sm);
}
.fs_md {
  font-size: var(--fs-md);
}
.fs_lg {
  font-size: var(--fs-lg);
}
.fs_xl {
  font-size: var(--fs-xl);
}
.fs_xxl {
  font-size: var(--fs-xxl);
}
.fs_4x {
  font-size: var(--fs-4x);
}
.fs_xxl {
  font-size: var(--fs-xxl);
}
.fs_2xl {
  font-size: var(--fs-2xl);
}
.fs_3xl {
  font-size: var(--fs-3xl);
}
/* ----------- */
/* COLORS */
.clr-light-black {
  color: var(--clr-light-black);
}
.clr-light-black-2 {
  color: var(--clr-light-black-2);
}
.bg-grey {
  background-color: var(--clr-grey);
}
.clr-black {
  color: var(--clr-black);
}
.clr-black-2 {
  background-color: var(--clr-black-2);
}
/* ---- */
/* LINE HEIGHT */
.lh-100 {
  line-height: var(--lh-100);
}
.lh-150 {
  line-height: var(--lh-150);
}
.lh-160 {
  line-height: var(--lh-160);
}
.lh-145 {
  line-height: var(--lh-145);
}
.lh-130 {
  line-height: var(--lh-130);
}
.lh-120 {
  line-height: var(--lh-120);
}
/* ----- */
/* LETTER SPACING */
.ls-046 {
  letter-spacing: var(--ls-046);
}
.ls-048 {
  letter-spacing: var(--ls-048);
}
.ls-06 {
  letter-spacing: var(--ls-06);
}
.ls-1-68 {
  letter-spacing: var(--ls-1-68);
}
.ls-2-1 {
  letter-spacing: var(--ls-2-1);
}
/* --------- */
/* OPACITY */
.opacity-09 {
  opacity: 0.9;
}
.opacity-08 {
  opacity: 0.8;
}
.opacity-07 {
  opacity: 0.7;
}
/* ---- */
/* BUTTONS */
.common-button {
  max-width: 100%;
  padding: 12px 26px;
  color: #fff;
  font-weight: 500;
  border-radius: 12px;
  background: var(--blue-linear);
  transition: 0.3s;
  border: none;
}
.common-button:hover {
  background: linear-gradient(180deg, #1194d9 0%, #1194d9 100%);
}
/* ------ */
/* HEADING */
.common-heading-class {
  font-family: "Baskerville old face";
  font-size: var(--fs-2xl);
  line-height: var(--lh-100);
  -webkit-text-stroke: 0.8px;
}
.product-page-common-heading {
  font-family: "Merriweather", serif;
  font-size: var(--fs-1xl);
  line-height: var(--lh-100);
  letter-spacing: var(--ls-072);
}
/* ----- */
/* IMAGE */
.image-border-radius {
  border-radius: 8px;
}
.img-hover:hover {
  scale: 1.1;
}
/* --- */
/* TRANSITIONS */
.transition-300 {
  transition: 0.3s ease-in-out;
}
/* ----- */
/* SLICK SLIDERS */
.slick-track {
  display: flex !important;
}
.slick-slide {
  height: inherit !important;
  display: flex !important;
  justify-content: center;
}
.slick-slide > div {
  display: flex;
}
/* ------ */
/* COMMON PRODUCT PAGE SEC CLASS */
.product-advantage-sec {
  margin-top: -4px;
}
/* ---- */
@media (min-width: 576px) {
  .logo {
    max-width: 180px;
  }
  .preloader-logo {
    width: 90px;
    max-width: unset;
  }
}
@media (min-width: 767.98px) {
  .hr-line {
    width: 272px;
  }
  .preloader-logo {
    width: 90px;
  }
}

@media (min-width: 1199.98px) {
  .vh_100 {
    height: 100vh;
  }
}
@media (min-width: 1199.98px) and (max-width: 1599.98px) {
  .custom_container {
    max-width: 1164px !important;
  }
}
