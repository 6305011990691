:root {
  /* font size */
  --fs-xxsm: 13px;
  --fs-xsm: 14px;
  --fs-sm: 16px;
  --fs-md: 18px;
  --fs-lg: 20px;
  --fs-xl: 22px;
  --fs-xxl: 24px;
  --fs-1xl: 26px;
  --fs-4x: 29px;
  --fs-2xl: 40px;
  --fs-3xl: 45px;
  /* colors */
  --blue-linear: linear-gradient(180deg, #1194d9 0%, #0570a8 100%);
  --blue-linear-2: linear-gradient(
    90deg,
    rgba(17, 149, 217, 0) 0%,
    #0b82c0 51.37%,
    rgba(17, 149, 217, 0) 100%
  );
  --clr-light-black: #403f3e;
  --clr-grey: #f0f1f2;
  --clr-white: #fff;
  --clr-black: #060b21;
  --clr-black-2: #030c19;
  --clr-light-black-2: #4d4d4d;
  --clr-grey-2: #f1f1f1;
  /* opacity */
  --opacity-07: 0.7;
  --opacity-08: 0.8;
  --opacity-09: 0.9;
  /* line height */
  --lh-100: 100%;
  --lh-120: 120%;
  --lh-130: 130%;
  --lh-145: 145%;
  --lh-150: 150%;
  --lh-160: 160%;
  /* letter spacing */
  --ls-046: 0.46px;
  --ls-048: 0.48px;
  --ls-06: 0.6px;
  --ls-016: -0.16px;
  --ls-1-68: 1.68px;
  --ls-2-1: 2.1px;
  --ls-072: 0.72px;
}
.countup-fs {
  font-size: 30px;
}
.fs-preloader-jmd {
  font-size: 24px;
}
.fs-preloader-rise {
  font-size: 20px;
}
@media (min-width: 575.78px) {
  :root {
    --fs-xxsm: 13px;
    --fs-xsm: 14px;
    --fs-sm: 16px;
    --fs-md: 18px;
    --fs-lg: 20px;
    --fs-xl: 22px;
    --fs-xxl: 24px;
    --fs-1xl: 28px;
    --fs-4x: 32px;
    --fs-2xl: 40px;
    --fs-3xl: 52px;
  }
  .countup-fs {
    font-size: 40px;
  }
}
@media (min-width: 767.98px) {
  :root {
    --fs-xxsm: 14px;
    --fs-xsm: 16px;
    --fs-sm: 18px;
    --fs-md: 19px;
    --fs-lg: 22px;
    --fs-xl: 24px;
    --fs-xxl: 26px;
    --fs-1xl: 32px;
    --fs-4x: 36px;
    --fs-2xl: 42px;
    --fs-3xl: 60px;
  }
  .countup-fs {
    font-size: 44px;
  }
}
@media (min-width: 1199.98px) {
  :root {
    --fs-xxsm: 14px;
    --fs-xsm: 16px;
    --fs-sm: 18px;
    --fs-md: 20px;
    --fs-lg: 24px;
    --fs-xl: 28px;
    --fs-xxl: 30px;
    --fs-1xl: 36px;
    --fs-4x: 40px;
    --fs-2xl: 50px;
    --fs-3xl: 64px;
  }
  .countup-fs {
    font-size: 50px;
  }
}
